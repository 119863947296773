import { ContainerLg } from './Container'
import { useSprings, animated } from 'react-spring'
import { HeadingMd } from '/features/buildingBlocks/Heading'
import { useTranslate } from '/machinery/I18n'
import { useIsInViewport } from '@kaliber/use-is-in-viewport'

import styles from './FactsAndFigures.css'

export function FactsAndFigures({ properties }) {
  const { ref: visibilityRef, isInViewport } = useIsInViewport({ rootMargin: '-10%' })
  const { __ } = useTranslate()
  const easingValues = [
    { fact: __`months`, number: properties?.duration },
    { fact: __`starting-spots`, number: properties?.startingSpots },
    { fact: __`departments`, number: properties?.departments }
  ]

  const easeInOut = (t) => t * (2 - t)
  const [springs] = useSprings(
    easingValues.filter(x => Boolean(x?.number)).length,
    (i) => ({
      config: {
        duration: 350,
        easing: easeInOut
      },
      value: isInViewport ? easingValues[i]?.number : 0,
      from: { value: 0 },
      delay: i * 200,
    }),
    [isInViewport]
  )

  return (
    <div data-style-context='blue' className={styles.component}>
      <ContainerLg>
        <div className={styles.content}>
          <HeadingMd h={3} title={__`facts-and-figures`} />
          <ul ref={visibilityRef} className={styles.list}>
            {springs.map((spring, i) => (
              <li className={styles.item} key={i}>
                <animated.div className={styles.value}>
                  {spring.value.to((val) => formatValueToTwoNumbers(val))}
                </animated.div>
                <div className={styles.label}>{easingValues[i].fact}</div>
              </li>
            ))}
          </ul>
        </div>
      </ContainerLg>
    </div>
  )
}

function formatValueToTwoNumbers(value) {
  return value.toFixed(0).padStart(2, '0')
}
